import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { ACTIVITY_PATH } from '../../../../constants/path';
import { ActivityGroupForm } from '../components/ActivityGroupForm';
import { fetchActivityGroupByName } from '../../../../store/slices/activityGroupSlice';
import { activityGroupSlice } from '../../../../store/slices/activityGroupSlice';

export const ActivityGroupEdit = () => {
    const dispatch = useDispatch();

    const router = useRouteMatch<{ name: string }>(ACTIVITY_PATH.EDIT_GROUP);
    const name = router?.params?.name;

    useEffect(() => {
        dispatch(activityGroupSlice.actions.setLoading(true));
        dispatch(fetchActivityGroupByName(name));

        return () => {
            dispatch(activityGroupSlice.actions.clearActivityGroup());
        };
    }, []);

    return <ActivityGroupForm mode="EDIT"></ActivityGroupForm>;
};

export default ActivityGroupEdit;
