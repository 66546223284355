import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import {
    Alert,
    AlertProps,
    Form,
    Button,
    Input,
    SpaceBetween,
    FormField,
    StatusIndicator,
    Modal,
    Box,
    TextContent,
} from '@amzn/awsui-components-react-v3';

import { ACTIVITY_PATH } from '../../../../constants/path';
import ActivityGroupDetails from './ActivityGroupDetails';
import DeliveryDetails from './DeliveryDetails';
import Delivery from './Delivery';
import { useActivityGroup } from '../hooks';
import { activityGroupSlice } from '../../../../store/slices/activityGroupSlice';
import scheduleManagementApi from '../../../../api/scheduleManagementApi';
import { ActivityGroupMode } from '../../../../interfaces/activityGroup';
import { createValidations, updateValidations } from '../validations';
import { ActivityStatus } from '../../Common/Common';
import { DeliverySession } from '../../../../interfaces/activity';
import { fetchActivityGroupByName } from '../../../../store/slices/activityGroupSlice';

export interface ActivityGroupFormProps {
    mode: ActivityGroupMode;
}

type ActivityGroupAlertConfig = {
    type?: AlertProps.Type;
    visible: boolean;
    text?: ActivityGroupAlertText;
};

export enum ActivityGroupAlertText {
    SAVE_SUCCESS = 'Activity Group saved successfully',
    SAVE_FAILURE = 'Failed to save Activity Group',
    WEBEX_SESSION_START_TIME_IN_PAST = 'Failed to save Activity Group. The start time of WebEx sessions can not be earlier than the current time.',
}

export const ActivityGroupForm = (props: ActivityGroupFormProps) => {
    const dispatch = useDispatch();
    const activityGroup = useActivityGroup();
    const [newDeliveryName, setNewDeliveryName] = useState('');
    const [shouldRedirectToEdit, setShouldRedirectToEdit] = useState(false);
    const [isStatusSynced, setIsStatusSynced] = useState(true);
    const [statusModalOpen, setStatusModalOpen] = useState(false);
    const [statusModalMessage, setStatusModalMessage] = useState('');
    const location = useLocation();
    const history = useHistory();
    const [alertConfig, setAlertConfig] = useState<ActivityGroupAlertConfig>({
        visible: false,
    });
    const [isSaving, setIsSaving] = useState(false);

    const [saveDisabled, saveDisabledReason] = useMemo(() => {
        switch (props.mode) {
            case 'CREATE': {
                for (const validation of Object.keys(createValidations)) {
                    if (!createValidations[validation].isValid(activityGroup)) {
                        return [
                            true,
                            createValidations[validation].invalidMessage,
                        ];
                    }
                }
                break;
            }
            case 'EDIT': {
                for (const validation of Object.keys(updateValidations)) {
                    if (!updateValidations[validation].isValid(activityGroup)) {
                        return [
                            true,
                            updateValidations[validation].invalidMessage,
                        ];
                    }
                }
                break;
            }
        }

        return [false, ''];
    }, [activityGroup]);

    useEffect(() => {
        history.push(location);
    }, [location.pathname]);

    useEffect(() => {
        if (alertConfig.visible) {
            const alertElement = document.querySelector(
                '#activity-group-alert',
            );

            if (alertElement) {
                alertElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [alertConfig]);

    useEffect(() => {
        if (activityGroup.status === ActivityStatus.Active && !isStatusSynced) {
            setStatusModalMessage(
                'Setting the Activity Group status to Active is a one-way change ' +
                    'that will result in Activities being made available for registration by customers.',
            );
        } else if (
            activityGroup.status === ActivityStatus.Canceled &&
            !isStatusSynced
        ) {
            setStatusModalMessage(
                'Setting the Activity Group status to Canceled is a one-way change ' +
                    'that will result in Activities being made unavailable for registration by customers.',
            );
        } else {
            setStatusModalMessage('');
        }
    }, [activityGroup.status]);

    const isExistingGroupMode = ['EDIT', 'VIEW'].includes(props.mode);
    const isReadOnlyMode = props.mode === 'VIEW';

    const shouldRedirectToView =
        isStatusSynced && activityGroup.status === ActivityStatus.Canceled;

    const handleClickSaveButton = () => {
        if (
            !isStatusSynced &&
            [
                ActivityStatus.Canceled.valueOf(),
                ActivityStatus.Active.valueOf(),
            ].includes(activityGroup.status)
        ) {
            setStatusModalOpen(true);
        } else {
            handleSaveActivityGroup();
        }
    };

    const goToDetailsPage = () => {
        const currentPath = history.location.pathname;
        const detailsPath = currentPath.replace('/edit', '');
        history.push(detailsPath);
    };

    const handleSaveActivityGroup = async () => {
        setIsSaving(true);
        try {
            await scheduleManagementApi.putActivityGroup({
                name: activityGroup.name,
                active: true,
                status: activityGroup.status,
                program_name: activityGroup.program_name,
                catalog_item_id: activityGroup.catalog_item_id,
                catalog_item_versioned_id:
                    activityGroup.catalog_item_versioned_id,
                start_timestamp: activityGroup.start_timestamp,
                end_timestamp: activityGroup.end_timestamp,
            });

            await Promise.all(
                activityGroup.child_activities.map(async (activity) => {
                    const {
                        pk,
                        delivery_name,
                        option_number,
                        delivery_sessions,
                        ...other_activity_properties
                    } = activity;

                    // Front-end client should not modify url or recording_url.
                    const truncated_sessions: DeliverySession[] = [];
                    if (delivery_sessions && delivery_sessions.length > 0) {
                        for (const session of delivery_sessions) {
                            if (session.v_ilt_info) {
                                const {
                                    url,
                                    recording_url,
                                    ...truncated_v_ilt_info
                                } = session.v_ilt_info;
                                truncated_sessions.push({
                                    ...session,
                                    v_ilt_info: truncated_v_ilt_info,
                                });
                            } else {
                                truncated_sessions.push(session);
                            }
                        }
                    }

                    const filteredActivity = {
                        ...other_activity_properties,
                        delivery_sessions: truncated_sessions,
                    };

                    if (pk) {
                        return scheduleManagementApi.updateActivityById({
                            id: pk,
                            activity: filteredActivity,
                        });
                    } else {
                        const genericDeliverySessions =
                            filteredActivity.delivery_sessions.map((ds) => {
                                const { id, ...rest } = ds;
                                return rest;
                            });
                        const {
                            result: { activity_id: createdActivityPk },
                        } = await scheduleManagementApi.createActivity({
                            ...filteredActivity,
                            delivery_sessions: genericDeliverySessions,
                        });

                        dispatch(
                            activityGroupSlice.actions.setDeliveryOption({
                                deliveryName: delivery_name,
                                optionNumber: option_number,
                                pk: createdActivityPk,
                            }),
                        );
                    }
                }),
            );

            setAlertConfig({
                type: 'success',
                visible: true,
                text: ActivityGroupAlertText.SAVE_SUCCESS,
            });

            setShouldRedirectToEdit(true);
            setIsStatusSynced(true);
        } catch {
            setAlertConfig({
                type: 'error',
                visible: true,
                text: ActivityGroupAlertText.SAVE_FAILURE,
            });
        } finally {
            setIsSaving(false);
            goToDetailsPage();
        }
    };

    const createNewDeliveryField = (
        <FormField label="Delivery Name">
            <SpaceBetween direction="horizontal" size="s">
                <Input
                    type="text"
                    value={newDeliveryName}
                    onChange={(event) => setNewDeliveryName(event.detail.value)}
                ></Input>

                <Button
                    onClick={() => {
                        dispatch(
                            activityGroupSlice.actions.addDeliveryName(
                                newDeliveryName,
                            ),
                        );
                        setNewDeliveryName('');
                    }}
                    disabled={!newDeliveryName}
                >
                    Add Delivery
                </Button>
            </SpaceBetween>
        </FormField>
    );

    const submitFormButton = (
        <Button
            variant="primary"
            formAction="submit"
            disabled={isSaving || saveDisabled}
            disabledReason={saveDisabledReason}
            loading={isSaving}
            onClick={() => {
                handleClickSaveButton();
            }}
            data-testid="activity-group-save-button"
        >
            {isSaving ? 'Saving...' : 'Save'}
        </Button>
    );

    const activityGroupAlert = (
        <div id="activity-group-alert">
            <Alert
                dismissible
                type={alertConfig.type}
                onDismiss={() =>
                    setAlertConfig({ ...alertConfig, visible: false })
                }
            >
                {alertConfig.text}
            </Alert>
        </div>
    );

    const activityGroupForm = (
        <Form actions={!isReadOnlyMode && submitFormButton}>
            <SpaceBetween size="l" direction="vertical">
                {alertConfig.visible && activityGroupAlert}

                <ActivityGroupDetails
                    mode={props.mode}
                    redirectToEdit={() => setShouldRedirectToEdit(true)}
                    setIsStatusSynced={setIsStatusSynced}
                ></ActivityGroupDetails>

                {isExistingGroupMode && (
                    <>
                        <DeliveryDetails
                            isReadOnlyMode={isReadOnlyMode}
                        ></DeliveryDetails>
                        {Object.keys(activityGroup.delivery_map).map(
                            (delivery) => (
                                <Delivery
                                    key={delivery}
                                    name={delivery}
                                    mode={props.mode}
                                ></Delivery>
                            ),
                        )}
                        {!isReadOnlyMode && createNewDeliveryField}
                    </>
                )}
            </SpaceBetween>

            {shouldRedirectToEdit && (
                <Redirect
                    to={`${ACTIVITY_PATH.EDIT_GROUP.replace(
                        ':name',
                        encodeURIComponent(activityGroup.name),
                    )}`}
                ></Redirect>
            )}

            {shouldRedirectToView && (
                <Redirect
                    to={`${ACTIVITY_PATH.GROUP_VIEW.replace(
                        ':name',
                        encodeURIComponent(activityGroup.name),
                    )}`}
                ></Redirect>
            )}

            <Modal
                visible={statusModalOpen}
                onDismiss={() => {
                    setStatusModalOpen(false);
                }}
                header="One-Way Status Change Detected"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                variant="normal"
                                onClick={() => setStatusModalOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    setStatusModalOpen(false);
                                    handleSaveActivityGroup();
                                }}
                            >
                                Acknowledge and Save
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                <TextContent>{statusModalMessage}</TextContent>
            </Modal>
        </Form>
    );

    const loadingSpinner = (
        <StatusIndicator type="loading">
            Loading Activity Group...
        </StatusIndicator>
    );

    return activityGroup.is_loading ? loadingSpinner : activityGroupForm;
};

export default ActivityGroupForm;
