import {
    PropertyFilter,
    PropertyFilterProps,
} from '@amzn/awsui-components-react-v3';
import { useState } from 'react';
import { createPropertyFilterI18nStrings } from '../../../../../common/utils/createPropertyFilterI18nStrings';
import {
    FILTERING_OPTIONS,
    FILTERING_PROPERTIES,
} from '../../../../enums/activityGroupPropertyFilterKeys';

const ActivityGroupListPropertyFilter = ({
    onChange,
}: {
    onChange: (val: PropertyFilterProps.Query) => void;
}) => {
    const [query, setQuery] = useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: 'and',
    });

    return (
        <PropertyFilter
            onChange={({ detail }) => {
                setQuery(detail);
                onChange(detail);
            }}
            query={query}
            hideOperations={true}
            filteringOptions={FILTERING_OPTIONS}
            filteringProperties={FILTERING_PROPERTIES}
            filteringErrorText="Error fetching suggestions."
            filteringAriaLabel="Find activity groups"
            filteringPlaceholder="Find activity groups"
            i18nStrings={createPropertyFilterI18nStrings({
                filteringAriaLabel: 'Find activity groups',
                filteringPlaceholder: 'Find activity groups',
            })}
        />
    );
};

export default ActivityGroupListPropertyFilter;
