import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { DATE_PICKER_DISPLAY_FORMAT } from '../../../../../common/utils/cloudscapeHelpers';
import { SelectProps } from '@amzn/awsui-components-react-v3';

export const useActivityGroupDeliveryWeekOptions = ({
    start,
    end,
    timezone,
    includeAllOption = false,
}) => {
    const weekOptionsForDates = useMemo(() => {
        const startTime = dayjs.unix(start).tz(timezone);
        const endTime = dayjs.unix(end).tz(timezone);
        const numWeeks = endTime.diff(startTime, 'week') + 1;

        const weekOptions: SelectProps.Option[] = [...new Array(numWeeks)].map(
            (_, index) => {
                const weekOfString = startTime
                    .add(index, 'week')
                    .startOf('week')
                    .format(DATE_PICKER_DISPLAY_FORMAT);

                return {
                    value: weekOfString,
                    label: `Week ${index + 1} (Starting: ${weekOfString})`,
                };
            },
        );

        if (includeAllOption) {
            weekOptions.unshift({ label: 'All weeks', value: 'all' });
        }

        return weekOptions;
    }, [start, end, timezone, includeAllOption]);

    const [selectedWeek, setSelectedWeek] = useState(weekOptionsForDates[0]);

    const handleWeekChange = useCallback((newWeek: SelectProps.Option) => {
        setSelectedWeek(newWeek);
    }, []);

    return {
        weekOptionsForDates,
        selectedWeek,
        handleWeekChange,
    };
};
