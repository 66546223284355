import { formatString } from '../../../../../common/utils/formatString';
import { startCase } from 'lodash';

export interface ActivityItem {
    id: string;
    delivery_name: string;
    option_number: number;
    date?: string;
    startTime?: string;
    endTime?: string;
    time?: string;
    class_size?: number;
    delivery_session_type?: string;
    dayOfWeek?: string;
    instructors?: string;
    meetingLinkType?: string;
    streamyard_url?: string;
    sessionId?: string;
    isSession: boolean;
    children?: ActivityItem[];
}

export interface ExtendedActivityItem extends ActivityItem {
    children?: ActivityItem[];
}

const columnProperties = [
    'delivery_name',
    'option_number',
    'date',
    'time',
    'dayOfWeek',
    'delivery_session_type',
    'meetingLinkType',
    'instructors',
    'sessionId',
] as const;

type ColumnProperty = (typeof columnProperties)[number];

const getCell = (property: ColumnProperty) => (item: ActivityItem) => {
    return formatString(item[String(property)]);
};

export const columnDefinitions = columnProperties.map((property) => ({
    id: property,
    header: startCase(property),
    cell: getCell(property),
}));
