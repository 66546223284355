import { useState, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ActivityGroupData } from '../../../../interfaces/activityGroup';
import { createActivityItem, createSessionItem } from '../components/helpers';
import {
    ActivityItem,
    ExtendedActivityItem,
} from '../components/SessionDetailsColumnDefinitions';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const useActivityGroupDeliveryDetails = (
    activityGroup: ActivityGroupData,
) => {
    const [expandedIds, setExpandedIds] = useState<Set<string>>(new Set());

    const generateActivityItems = useMemo(() => {
        return activityGroup.child_activities.map((activity) => {
            const activityItem = createActivityItem({
                delivery_name: activity.delivery_name,
                option_number: activity.option_number,
            });
            const sessionItems = activity.delivery_sessions.map((session) => {
                const sessionId = `${activity.activity_name}-${session.id}`;
                return createSessionItem(activity, session, sessionId);
            });
            return {
                ...activityItem,
                children: sessionItems,
            };
        });
    }, [activityGroup]);

    const filterVisibleItems = useCallback(
        (week: string) => {
            return generateActivityItems
                .map((item) => ({
                    ...item,
                    children: item.children?.filter((session) => {
                        if (week === 'all') return true;
                        const sessionDate = dayjs(session.date);
                        const weekStart = dayjs(week);
                        const weekEnd = weekStart.add(1, 'week');
                        return (
                            sessionDate.isAfter(weekStart) &&
                            sessionDate.isBefore(weekEnd)
                        );
                    }),
                }))
                .filter((item) => item.children && item.children.length > 0);
        },
        [generateActivityItems],
    );

    const handleExpandableItemToggle = useCallback(
        ({
            detail,
        }: {
            detail: { item: ExtendedActivityItem; expanded: boolean };
        }) => {
            const { item, expanded } = detail;
            setExpandedIds((prev) => {
                const newSet = new Set(prev);
                if (expanded) {
                    newSet.add(item.id);
                } else {
                    newSet.delete(item.id);
                }
                return newSet;
            });
        },
        [],
    );

    const getItemChildren = useCallback(
        (item: ExtendedActivityItem): ActivityItem[] => item.children || [],
        [],
    );

    const isItemExpandable = useCallback(
        (item: ExtendedActivityItem): boolean =>
            !item.isSession && !!item.children && item.children.length > 0,
        [],
    );

    return {
        filterVisibleItems,
        handleExpandableItemToggle,
        getItemChildren,
        isItemExpandable,
        expandedIds,
    };
};
