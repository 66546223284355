import React from 'react';
import {
    Box,
    Button,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react-v3';
import { Link } from 'react-router-dom';
import { columnDefinitions } from './SessionDetailsColumnDefinitions';
import TableHeader from '../../../../../common/components/TableHeader/TableHeader';
import { ActivityGroupData } from '../../../../interfaces/activityGroup';
import { useActivityGroupDeliveryDetails } from '../hooks/useActivityGroupDeliveryDetails';

interface ActivityGroupSessionsTableProps {
    activityGroup: ActivityGroupData;
    selectedWeek: string;
}

export const ActivityGroupSessionsTable = ({
    activityGroup,
    selectedWeek,
}: ActivityGroupSessionsTableProps) => {
    const {
        filterVisibleItems,
        handleExpandableItemToggle,
        getItemChildren,
        isItemExpandable,
        expandedIds,
    } = useActivityGroupDeliveryDetails(activityGroup);

    const visibleItems = filterVisibleItems(selectedWeek);
    const sessionCount = visibleItems.flatMap(
        (item) => item.children || [],
    ).length;

    return (
        <Table
            data-testid="DeliverySessionsTable"
            columnDefinitions={columnDefinitions}
            items={visibleItems}
            expandableRows={{
                getItemChildren,
                isItemExpandable,
                expandedItems: visibleItems.filter((item) =>
                    expandedIds.has(item.id),
                ),
                onExpandableItemToggle: handleExpandableItemToggle,
            }}
            header={
                <TableHeader
                    title="Sessions"
                    actions={null}
                    count={sessionCount > 0 ? sessionCount : null}
                />
            }
            empty={
                selectedWeek !== 'all' ? (
                    <Box
                        margin={{ vertical: 'xs' }}
                        textAlign="center"
                        color="inherit"
                    >
                        <SpaceBetween size="m">
                            <b>No sessions for the selected week</b>
                            <Box variant="p" color="inherit">
                                Try selecting a different week or "All weeks" to
                                see sessions.
                            </Box>
                        </SpaceBetween>
                    </Box>
                ) : (
                    <Box
                        margin={{ vertical: 'xs' }}
                        textAlign="center"
                        color="inherit"
                    >
                        <SpaceBetween size="m">
                            <b>No sessions created for activity</b>
                            <Link
                                to={`/activities/group/${activityGroup.name}/edit`}
                            >
                                <Button data-testid="EditGroupButton">
                                    Add Session
                                </Button>
                            </Link>
                        </SpaceBetween>
                    </Box>
                )
            }
        />
    );
};

export default ActivityGroupSessionsTable;
