import {
    Container,
    Header,
    SpaceBetween,
    FormField,
    Select,
} from '@amzn/awsui-components-react-v3';
import { ActivityGroupData } from '../../../../interfaces/activityGroup';
import { ActivityGroupSessionsTable } from './ActivityGroupSessionsTable';
import { useActivityGroupDeliveryWeekOptions } from '../hooks/useActivityGroupDeliveryWeekOptions';

export const ActivityGroupDeliveryDetails = ({
    activityGroup,
}: {
    activityGroup: ActivityGroupData;
}) => {
    const { weekOptionsForDates, selectedWeek, handleWeekChange } =
        useActivityGroupDeliveryWeekOptions({
            start: activityGroup.start_timestamp,
            end: activityGroup.end_timestamp,
            timezone: activityGroup.selected_timezone,
            includeAllOption: true,
        });

    return (
        <Container header={<Header variant="h2">Delivery details</Header>}>
            <SpaceBetween size="l" direction="vertical">
                <FormField stretch={true} label="Week">
                    <Select
                        options={weekOptionsForDates}
                        selectedOption={selectedWeek}
                        onChange={({ detail }) =>
                            handleWeekChange(detail.selectedOption)
                        }
                    />
                </FormField>
                <ActivityGroupSessionsTable
                    activityGroup={activityGroup}
                    selectedWeek={selectedWeek.value}
                />
            </SpaceBetween>
        </Container>
    );
};
