import Section from '../../../../../common/components/Section/Section';
import { SectionContentType } from '../../../../../common/constants/grimsby';
import { formatString } from '../../../../../common/utils/formatString';
import { ActivityGroupData } from '../../../../interfaces/activityGroup';
import { formatGrimsbyDate } from '../../Common/Common';

export const ActivityGroupDetailsContent = ({
    activityGroup,
}: {
    activityGroup: ActivityGroupData;
}) => {
    const detailsContentObject = {
        type: SectionContentType.Column,
        columnsCount: 2,
        columns: [
            [
                {
                    key: 'Activity Group Name',
                    value: formatString(activityGroup.name),
                },
                {
                    key: 'Program',
                    value: formatString(activityGroup.program_name),
                },
                {
                    key: 'Course',
                    value: formatString(activityGroup.course_name),
                },
                { key: 'Status', value: formatString(activityGroup.status) },
            ],
            [
                {
                    key: 'Time zone',
                    value: formatString(activityGroup.selected_timezone),
                },
                {
                    key: 'Start Date',
                    value: formatGrimsbyDate(
                        activityGroup.start_timestamp,
                        activityGroup.selected_timezone,
                    ),
                },
                {
                    key: 'End Date',
                    value: formatGrimsbyDate(
                        activityGroup.end_timestamp,
                        activityGroup.selected_timezone,
                    ),
                },
            ],
        ],
    };

    return (
        <Section
            header={{ label: 'Activity details' }}
            content={detailsContentObject}
        />
    );
};
