import dayjs from 'dayjs';
import {
    formatGrimsbyDate,
    formatDeliverySessionTime,
} from '../../Common/Common';
import {
    ActivityItem,
    ExtendedActivityItem,
} from './SessionDetailsColumnDefinitions';
import { formatTime12Hour } from '../../../../../common/utils/date-time.utils';
import { ActivityGroupChildActivity } from '../../../../interfaces/activityGroup';
import { DeliverySession } from '../../../../interfaces/activity';

export const createActivityItem = ({
    delivery_name,
    option_number,
}: {
    delivery_name: string;
    option_number: number;
}): ExtendedActivityItem => ({
    id: delivery_name,
    delivery_name: delivery_name,
    option_number: option_number,
    isSession: false,
});

export const createSessionItem = (
    activity: ActivityGroupChildActivity,
    session: DeliverySession,
    sessionId: string,
): ActivityItem => {
    const sessionDate = dayjs
        .unix(session.start_timestamp)
        .tz(activity.delivery_timezone);

    return {
        id: sessionId,
        delivery_name: activity.delivery_name,
        option_number: activity.option_number,
        date: formatGrimsbyDate(
            session.start_timestamp,
            activity.delivery_timezone,
        ),
        startTime: formatTime12Hour(sessionDate.toDate()),
        endTime: formatTime12Hour(
            dayjs
                .unix(session.end_timestamp)
                .tz(activity.delivery_timezone)
                .toDate(),
        ),
        time: formatDeliverySessionTime(session, activity.delivery_timezone),
        class_size: activity.class_size,
        delivery_session_type: session.delivery_session_type,
        dayOfWeek: sessionDate.format('dddd'),
        instructors:
            session.instructors
                ?.map(
                    (instructor: { name: string; email: string }) =>
                        `${instructor.name} (${instructor.email})`,
                )
                .join(', ') || '',
        meetingLinkType: session.v_ilt_info?.type || '',
        streamyard_url: session.v_ilt_info?.streamyard_url || '',
        sessionId: session.id,
        isSession: true,
    };
};
