import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { ACTIVITY_PATH } from '../../../../constants/path';
import { fetchActivityGroupByName } from '../../../../store/slices/activityGroupSlice';
import { activityGroupSlice } from '../../../../store/slices/activityGroupSlice';
import { useActivityGroup } from '../hooks';
import { ActivityGroupDeliveryDetails } from '../components/ActivityGroupDeliveryDetails';
import Loader from '../../../../../main/Loader/Loader';
import { ActivityGroupDetailsContent } from '../components/ActivityGroupDetailsContent';
import { Button, Header } from '@amzn/awsui-components-react-v3';
import { formatString } from '../../../../../common/utils/formatString';

export const ActivityGroupDetails = () => {
    const dispatch = useDispatch();
    const {
        params: { name },
    } = useRouteMatch<{ name: string }>(ACTIVITY_PATH.GROUP_VIEW);

    useEffect(() => {
        dispatch(activityGroupSlice.actions.setLoading(true));
        dispatch(fetchActivityGroupByName(name));

        return () => {
            dispatch(activityGroupSlice.actions.clearActivityGroup());
        };
    }, []);

    const activityGroup = useActivityGroup();

    return (
        <Loader
            isLoading={activityGroup.is_loading}
            children={
                <>
                    <Header
                        variant="h1"
                        actions={
                            <Link
                                to={`/activities/group/${activityGroup.name}/edit`}
                            >
                                <Button data-testid="EditGroupButton">
                                    Edit Group
                                </Button>
                            </Link>
                        }
                    >
                        <span data-testid="ActivityTitle">
                            {formatString(activityGroup.name)}
                        </span>
                    </Header>
                    <ActivityGroupDetailsContent
                        activityGroup={activityGroup}
                    />
                    <ActivityGroupDeliveryDetails
                        activityGroup={activityGroup}
                    />
                </>
            }
        />
    );
};

export default ActivityGroupDetails;
